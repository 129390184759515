<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <router-link to="/login" class="back-button">
            <ion-icon slot="icon-only" color="tetranary" :icon="chevronBackOutline"></ion-icon>
          </router-link>
        </ion-buttons>
        <ion-title color="primary">
          <h2 class="mb-0 ion-text-center">Update</h2>
        </ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :scroll-events="true">
      <div id="container">
        <form
          action=""
          @submit.prevent="request"
          class="horizontally-centered"
          v-if="currentStep === 1"
        >
          <div class="ion-padding ion-margin-top">
            <p>
              Please enter your email below to receive a code which will allow you to reset your
              password.
            </p>
            <p>
              If you do not see the email in your inbox please check your junk/spam folder.
            </p>
          </div>

          <ion-grid>
            <ion-row class="ion-justify-content-center ion-align-items-center">
              <ion-col align-self-center>
                <ion-item class="ion-margin-top" lines="none">
                  <ion-label position="stacked">Email</ion-label>
                  <ion-input type="email" required v-model="email"></ion-input>
                </ion-item>

                <div class="ion-margin-top ion-padding-top">
                  <ion-button size="large" expand="block" type="submit" color="tertiary">
                    Get code
                    <!-- <ion-ripple-effect></ion-ripple-effect> -->
                  </ion-button>

                  <div class="ion-text-center ion-margin-top">
                    <button class="button-as-link" @click.prevent="currentStep = 2">
                      Already have code
                    </button>
                  </div>
                </div>

                <transition name="fade" mode="out-in">
                  <div class="ion-padding" v-if="error">
                    <ion-text color="danger ion-text-center" v-html="error"> </ion-text>
                  </div>
                </transition>
              </ion-col>
            </ion-row>
          </ion-grid>
        </form>
        <form
          action=""
          @submit.prevent="submitCode"
          class="horizontally-centered"
          v-else-if="currentStep === 2"
        >
          <ion-grid>
            <ion-row class="ion-justify-content-center ion-align-items-center">
              <ion-col align-self-center>
                <template v-if="firstStepSubmitted">
                  <h2>Thank you.</h2>
                  <p>We have emailed you a verification code.</p>
                  <p>Please confirm this code to change your password.</p>
                </template>
                <template v-else>
                  <h2>Great!</h2>
                  <p>Enter your email and verification code below.</p>
                </template>

                <ion-item class="ion-margin-top" lines="none" v-if="!firstStepSubmitted">
                  <ion-label position="stacked">Email</ion-label>
                  <ion-input type="email" required v-model="email"></ion-input>
                </ion-item>

                <ion-item class="ion-margin-top" lines="none">
                  <ion-label position="stacked">Code</ion-label>
                  <ion-input type="text" maxlength="4" required v-model="code"></ion-input>
                </ion-item>

                <div class="ion-margin-top ion-padding-top">
                  <ion-button size="large" expand="block" type="submit" color="tertiary">
                    Submit code
                  </ion-button>
                </div>

                <transition name="fade" mode="out-in">
                  <div class="ion-padding" v-if="error">
                    <ion-text color="danger" v-html="error"> </ion-text>
                  </div>
                </transition>
              </ion-col>
            </ion-row>
          </ion-grid>
        </form>
        <form
          action=""
          @submit.prevent="changePassword"
          class="horizontally-centered"
          v-else-if="currentStep === 3"
        >
          <ion-grid>
            <ion-row class="ion-justify-content-center ion-align-items-center">
              <ion-col align-self-center>
                <h2>Code validated</h2>
                <p>Please choose your new password.</p>

                <ion-item class="ion-margin-top" lines="none" v-if="!secondStepSubmitted">
                  <ion-label position="stacked">Email</ion-label>
                  <ion-input type="email" required v-model="email"></ion-input>
                </ion-item>

                <ion-item class="ion-margin-top" lines="none" v-if="!secondStepSubmitted">
                  <ion-label position="stacked">Code</ion-label>
                  <ion-input type="text" maxlength="4" required v-model="code"></ion-input>
                </ion-item>

                <ion-item class="ion-margin-top" lines="none">
                  <ion-label position="stacked">New Password</ion-label>
                  <ion-input type="password" required v-model="password"></ion-input>
                </ion-item>

                <div class="ion-margin-top ion-padding-top">
                  <ion-button size="large" expand="block" type="submit" color="primary">
                    Set new password
                  </ion-button>
                </div>

                <transition name="fade" mode="out-in">
                  <div class="ion-padding" v-if="error">
                    <ion-text color="danger" v-html="error"> </ion-text>
                  </div>
                </transition>
              </ion-col>
            </ion-row>
          </ion-grid>
        </form>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { chevronBackOutline } from 'ionicons/icons';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { reactive, toRefs } from 'vue';

import axios from '@/axios';
import {
  IonPage,
  IonIcon,
  IonText,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonInput,
  IonButton
} from '@ionic/vue';

export default {
  name: 'Register',
  components: {
    IonPage,
    IonIcon,
    IonText,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    IonItem,
    IonLabel,
    IonInput,
    IonButton
  },
  setup(): any {
    const store = useStore();
    const route = useRouter();

    const state = reactive({
      firstStepSubmitted: false,
      secondStepSubmitted: false,
      formSubmitted: false,
      currentStep: 1,
      error: '',
      code: ''
    });

    const user = reactive({
      email: '',
      password: ''
    });

    const APIRequest = function() {
      return axios.post('wp-json/user/v1/request-password', {
        email: user.email
      });
    };

    const APIcheckCode = function() {
      return axios.post('wp-json/user/v1/request-password', {
        email: user.email,
        code: state.code
      });
    };

    const APIchangePassword = function() {
      return axios.post('wp-json/user/v1/request-password', {
        email: user.email,
        password: user.password,
        code: state.code
      });
    };

    const submitRequestSuccessful = function(res) {
      if (res.status !== 200) {
        submitRequestFailed(res);
      }

      state.firstStepSubmitted = true;
      state.currentStep = 2;
      state.error = '';
      // login();
    };

    const submitCodeSuccessful = function(res) {
      if (res.status !== 200) {
        submitRequestFailed(res);
      }

      state.secondStepSubmitted = true;
      state.currentStep = 3;
      state.error = '';
      // login();
    };

    const changePasswordSuccessful = function(res) {
      if (res.status !== 200) {
        changePasswordFailed(res);
      }

      login();
    };

    const APIlogin = function() {
      return axios.post('wp-json/jwt-auth/v1/token', {
        username: user.email,
        password: user.password
      });
    };

    const loginFailed = function(err) {
      state.formSubmitted = false;
      const message = 'There was a problem with the login process. Please try again later.';
      state.error = err.response?.data?.message || message;
      // this.$store.dispatch('logout');
    };

    const loginSuccessful = function(res) {
      if (res.status !== 200 || !res.data?.token) {
        loginFailed(res);
      }

      // store details in localStorage
      store.dispatch('auth/login', res.data.token);
      // get user data
      store.dispatch('user/getDataAPI');
      route.replace('/profile');
    };

    const login = async function() {
      await APIlogin()
        .then(loginSuccessful)
        .catch(loginFailed);
    };

    const submitRequestFailed = function(err) {
      state.formSubmitted = false;

      let message =
        err.response?.data?.message ||
        'There was a problem with the login process. Please try again later.';

      state.error = message;
    };

    const submitCodeFailed = function(err) {
      state.formSubmitted = false;

      let message =
        err.response?.data?.message ||
        'That seems to be an invalid code, please check your email for your code.';

      state.error = message;
    };

    const changePasswordFailed = function(err) {
      state.formSubmitted = false;

      let message = err.response?.data?.message || 'Issue changing password';

      state.error = message;
    };

    const request = async function() {
      await APIRequest()
        .then(submitRequestSuccessful)
        .catch(submitRequestFailed);
    };

    const submitCode = async function() {
      await APIcheckCode()
        .then(submitCodeSuccessful)
        .catch(submitCodeFailed);
    };

    const changePassword = async function() {
      await APIchangePassword()
        .then(changePasswordSuccessful)
        .catch(changePasswordFailed);
    };

    return {
      ...toRefs(user),
      ...toRefs(state),
      request,
      submitCode,
      changePassword,
      chevronBackOutline
    };
  }
};
</script>
